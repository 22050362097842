<template>
	<div class="component c-slide c-slide--sponsors h-100 w-100">
		<div class="c-slide__label text-primary">Links</div>
		<div class="c-slide__container row py-3 px-1">
			<ul class="c-slide__list reset-list d-flex align-items-center">
				<li
					class="c-slide__list-item"
					v-for="sponsor in sponsors"
					:key="sponsor.id"
				>
					<a
						:href="sponsor.url"
						class="c-slide__list-url d-block text-decoration-none"
						target="_blank"
					>
						<img
							:src="`${public_path}images/${sponsor.image}`"
							:alt="sponsor.title"
							class="c-slide__list-img"
						/>
					</a>
				</li>
			</ul>
		</div>
		<MainFooter />
	</div>
</template>

<script>
import MainFooter from '@/components/MainFooter';

export default {
	name: 'SlideSponsors',
	components: {
		MainFooter,
	},
	data() {
		return {
			public_path: process.env.BASE_URL,
			sponsors: [
				{
					id: 1,
					image: 'links_football.png',
					title: 'Baylor Football',
					url: 'https://baylorbears.com/sports/football',
				},
				{
					id: 2,
					image: 'links_big12.png',
					title: 'Big 12 Conference',
					url: 'https://big12sports.com/',
				},
				{
					id: 3,
					image: 'links_foundation.png',
					title: 'Baylor Bear Foundation',
					url: 'http://bearfoundation.baylorbears.com/',
				},
				{
					id: 4,
					image: 'links_club_2023.png',
					title: 'Cub Club',
					url:
						'https://baylorbears.com/sports/2018/5/16/fanengagement-bay-cub-club-html.aspx',
				},
				{
					id: 5,
					image: 'links_nike.png',
					title: 'NIKE',
					url: 'https://shop.baylorbears.com/collections/nike%C2%AE',
				},
				{
					id: 6,
					image: 'links_bold.png',
					title: 'Baylor Bold Rewards',
					url:
						'https://baylorbears.com/sports/2019/8/9/baylor-bold-rewards.aspx',
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-slide--sponsors {
	.c-slide {
		&__list {
			justify-content: flex-start;
			max-width: 100%;
			overflow-x: auto;
			padding-bottom: $spacer;

			&-item {
				flex: 0 0 auto;
				margin-right: $spacer;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		@media screen and (min-width: 1281px) {
			&__list {
				flex-wrap: wrap;
				justify-content: center;
				overflow-x: auto;

				&-item {
					display: flex;
					flex: 0 1 33.3333%;
					justify-content: center;
					margin: 0;
					padding: $spacer * 0.5;
				}
			}
			&__container {
				height: calc(100vh - 242px);
			}
		}
	}
}
</style>
