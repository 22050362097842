<template>
	<main class="home" :key="render_key">
		<div id="pagepiling">
			<section class="s-page s-page--intro" id="intro">
				<h2 class="hide">Gameday</h2>
				<SlideHero />
			</section>
			<section
				class="s-page s-page--timeline pp-scrollable"
				id="timeline"
			>
				<h2 class="hide">Timeline</h2>
				<SlideTimeline />
			</section>
			<section
				class="s-page s-page--experience pp-scrollable"
				id="experience"
			>
				<h2 class="hide">Experience</h2>
				<SlideExperience />
			</section>
			<section class="s-page s-page--hype" id="hype">
				<h2 class="hide">Hype</h2>
				<SlideHype />
			</section>
			<section class="s-page s-page--sponsors" id="links">
				<h2 class="hide">Links</h2>
				<SlideSponsors />
			</section>
			<ul class="c-menu component reset-list fixed" id="pagePilingMenu">
				<li data-menuanchor="intro" class="c-menu__item active">
					<a href="#intro" class="c-menu__link relative">
						<div
							class="c-menu__label absolute d-flex align-items-center"
						>
							<span class="c-menu__label-text">Gameday</span>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-home"
							></span>
						</div>
					</a>
				</li>
				<li data-menuanchor="timeline" class="c-menu__item">
					<a href="#timeline" class="c-menu__link relative">
						<div
							class="c-menu__label absolute d-flex align-items-center"
						>
							<span class="c-menu__label-text">Timeline</span>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-timeline"
							></span>
						</div>
					</a>
				</li>
				<li data-menuanchor="experience" class="c-menu__item">
					<a href="#experience" class="c-menu__link relative">
						<div
							class="c-menu__label absolute d-flex align-items-center"
						>
							<span class="c-menu__label-text">Experience</span>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-group"
							></span>
						</div>
					</a>
				</li>
				<li data-menuanchor="hype" class="c-menu__item">
					<a href="#hype" class="c-menu__link relative">
						<div
							class="c-menu__label absolute d-flex align-items-center"
						>
							<span class="c-menu__label-text">Hype</span>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-tv"
							></span>
						</div>
					</a>
				</li>
				<li data-menuanchor="sponsors" class="c-menu__item">
					<a href="#links" class="c-menu__link relative">
						<div
							class="c-menu__label absolute d-flex align-items-center"
						>
							<span class="c-menu__label-text">Links</span>
							<span
								aria-hidden="true"
								class="sidearm-icon sf-info-circle"
							></span>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</main>
</template>

<script>
import jQuery from 'jquery';
/* eslint-disable */
import pagepiling from 'pagepiling.js';
/* eslint-enable */
import '../../node_modules/pagepiling.js/dist/jquery.pagepiling.min.css';
import SlideExperience from '@/components/slides/SlideExperience';
import SlideHero from '@/components/slides/SlideHero';
import SlideHype from '@/components/slides/SlideHype';
import SlideSponsors from '@/components/slides/SlideSponsors';
import SlideTimeline from '@/components/slides/SlideTimeline';

let $ = jQuery;

export default {
	name: 'Home',

	components: {
		SlideExperience,
		SlideHero,
		SlideHype,
		SlideSponsors,
		SlideTimeline,
	},

	data() {
		return {
			previous_window_width: window.innerWidth,
			render_key: Date.now(),
		};
	},

	methods: {
		init_pagepile() {
			if (window.matchMedia('(min-width: 1281px)').matches) {
				document.body.classList.add('pp-viewing-intro');

				this.$nextTick(() => {
					this.pagepile('#pagepiling');
				});
			}
		},
		pagepile(el) {
			$(el).pagepiling({
				anchors: ['intro', 'timeline', 'experience', 'hype', 'links'],
				direction: 'vertical',
				easing: 'linear',
				menu: '#pagePilingMenu',
				navigation: false,
				normalScrollElementTouchThreshold: 50,
				touchSensitivity: 50,
				scrollingSpeed: 250,
				sectionSelector: '.s-page',
				onLeave(index, nextIndex) {
					if (nextIndex === 1 || nextIndex === 4) {
						document.body.classList.remove('solid-color-nav');
					} else {
						document.body.classList.add('solid-color-nav');
					}
					if (index === 4 && nextIndex != 4) {
						const video = document.getElementById('video');
						video.pause();
					}
				},
			});
		},
		scroll_to_section(hash) {
			$('html, body').animate(
				{
					scrollTop: $(hash).offset().top - 92,
				},
				500,
			);
		},
	},

	created() {
		if (window.matchMedia('(min-width: 1281px)').matches) {
			document.body.classList.add('pp-viewing-intro');
		}

		document.body.classList.remove('common-page');
		document.body.classList.add('home-page');
	},

	mounted() {
		this.init_pagepile();

		window.addEventListener('resize', () => {
			if (
				(this.previous_window_width < 1281 &&
					window.innerWidth >= 1281) ||
				(this.previous_window_width >= 1281 && window.innerWidth < 1281)
			) {
				this.render_key = Date.now();
			}

			this.previous_window_width = window.innerWidth;
		});
	},

	watch: {
		render_key() {
			this.init_pagepile();
		},

		$route(to) {
			if (window.innerWidth < 1281 && to.hash) {
				this.scroll_to_section(to.hash);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.s-page {
	padding: $spacer * 3 $spacer 0;

	&--intro {
		height: 100vh;
		padding: 0;
	}

	&--experience {
		padding-bottom: $spacer * 3;
	}

	&--hype {
		padding: 0;
	}

	&--sponsors {
		padding: 0;
	}
}

.c-menu {
	display: none;
}

@media screen and (max-width: 1280px) {
	.pp-scrollable {
		overflow-y: initial !important;
	}
}

@media screen and (min-width: 1281px) {
	.home {
		height: 100vh;
		padding-top: 0;
		width: 100vw;
	}

	.s-page {
		height: 100%;
		opacity: 0;
		padding: 0;
		pointer-events: none;
		transition: 0.35s;
		transform: none !important;
		width: 100%;

		&.active {
			opacity: 1;
			pointer-events: auto;
		}

		&:not(.pp-scrollable) {
			overflow: hidden;
		}

		&--timeline,
		&--experience,
		&--sponsors {
			&.active {
				.component {
					opacity: 1;
					transform: translateY(0);
					transition: 0.25s 0.5s;
				}
			}

			.component {
				opacity: 0;
				transform: translateY(#{$spacer * 4});
				transition: 0.25s;
			}
		}
	}

	.c-menu {
		display: block;
		opacity: 0;
		pointer-events: none;
		top: 50%;
		right: 48px;
		transform: translateY(calc(-50% - 24px));
		transition: 0.25s;
		z-index: 6;

		body:not(.pp-viewing-intro) & {
			opacity: 1;
			pointer-events: auto;
			transform: translateY(-50%);
			transition: 0.25s 0.35s;
		}

		body.hype-video-playing & {
			opacity: 0;
			pointer-events: none;
			transition: 0.25s;
		}

		&__item {
			padding: $spacer * 1.5 0;

			&.active .c-menu__link {
				background: $color-primary;
			}
			body.pp-viewing-hype & {
				&.active .c-menu__link {
					background: $color-secondary;
				}
			}
			&:not(.active) .c-menu__link {
				@include pulsating_button(8, $color-lightest);

				&:hover,
				&:focus {
					cursor: pointer;

					.c-menu__label {
						opacity: 1;
						pointer-events: auto;
						transform: translate(8px, -50%) scaleX(1);

						&-text,
						.sidearm-icon {
							opacity: 1;
							transition: 0.25s 0.25s;
						}
					}
				}
			}
		}

		&__link {
			background: $color-lightest;
			border-radius: 50%;
			display: block;
			height: 8px;
			position: relative;
			text-decoration: none;
			transition: 0.25s;
			width: 8px;

			&:hover,
			&:focus {
				cursor: initial;
			}
		}

		&__label {
			background: $color-secondary;
			border-radius: 50px;
			color: $color-primary;
			display: inline-block;
			height: 50px;
			line-height: 50px;
			opacity: 0;
			padding: 0 $spacer;
			pointer-events: none;
			top: 50%;
			right: 0;
			text-align: center;
			text-transform: uppercase;
			transform: translate(0, -50%) scaleX(0);
			transform-origin: right;
			transition: 0.25s;
			white-space: nowrap;

			&-text {
				font-family: $font-oswald;
				font-size: 1rem;
				font-weight: 500;
				opacity: 0;
				transition: 0.25s;
			}

			.sidearm-icon {
				opacity: 0;
				transition: 0.25s;
			}
		}
	}
}
</style>
