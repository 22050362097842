<template>
	<div class="component c-slide c-slide--hype relative">
		<div class="c-slide__label text-white">Hype</div>
		<div
			class="c-slide__video-container absolute h-100 w-100"
			:class="playing ? 'is-playing' : ''"
		>
			<video
				class="c-slide__video bg-black"
				id="video"
				preload="meta"
				poster="~@/assets/hype_bg_2024.jpg"
				:class="
					playing
						? 'h-100 w-100 absolute'
						: 'object-fit-positioning object-fit-cover object-fit-center'
				"
				:controls="playing ? true : false"
				v-play="playing"
				v-on:mousemove="update_parallax"
				:style="!mobile && !playing ? parallax_bg : ''"
			>
				<source src="~@/assets/hype_video_2023.mp4" type="video/mp4" />
				<p>
					Sorry, there's a problem playing this video. Please try
					using a different browser.
				</p>
			</video>
		</div>
		<button
			aria-label="Play video"
			v-if="!playing"
			transition="fade"
			class="c-slide__play-button absolute"
			@click="play"
			type="button"
		>
			<svg
				fill="none"
				preserveAspectRatio="xMinYMin meet"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					class="circle-1"
					cx="150"
					cy="150"
					r="148.5"
					stroke="#fff"
					stroke-width="3"
				/>
				<circle
					class="circle-3"
					cx="150.5"
					cy="150.5"
					r="83"
					stroke="#FFB71B"
					stroke-width="3"
				/>
				<path
					class="triangle"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M128.77 115c-1.96 0-3.77 1.562-3.77 3.767v61.425c0 2.205 1.81 3.768 3.77 3.768.667 0 1.35-.18 1.985-.578l49.14-30.712c2.357-1.473 2.357-4.907 0-6.38l-49.14-30.713a3.721 3.721 0 00-1.985-.577zm1.23 6l45.565 28.48L130 177.96V121z"
					fill="#FFB71B"
				/>
				<mask
					id="a"
					maskUnits="userSpaceOnUse"
					x="125"
					y="115"
					width="57"
					height="69"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M128.77 115c-1.96 0-3.77 1.562-3.77 3.767v61.425c0 2.205 1.81 3.768 3.77 3.768.667 0 1.35-.18 1.985-.578l49.14-30.712c2.357-1.473 2.357-4.907 0-6.38l-49.14-30.713a3.721 3.721 0 00-1.985-.577zm1.23 6l45.565 28.48L130 177.96V121z"
						fill="#fff"
					/>
				</mask>
			</svg>
			<div class="fill"></div>
			<div class="line"></div>
		</button>
	</div>
</template>

<script>
import { debounce, throttle } from 'lodash-es';

export default {
	name: 'SlideHype',

	data() {
		return {
			mobile: false,
			parallaxX: 0,
			parallaxY: 0,
			playing: false,
		};
	},

	methods: {
		check_window_width: debounce(function() {
			if (window.matchMedia('(max-width: 1280px)').matches) {
				this.mobile = true;
			} else {
				this.mobile = false;
			}
		}, 20),
		play() {
			this.playing = true;
		},
		update_parallax: throttle(function(event) {
			let x = event.clientX;
			let y = event.clientY;
			let screenWidth = window.innerWidth;
			let screenHeight = window.innerHeight;

			this.parallaxX = x / screenWidth;
			this.parallaxY = y / screenHeight;
		}, 20),
	},

	computed: {
		paused() {
			return !this.playing;
		},
		parallax_bg: function() {
			return this.$mq == 'sm'
				? ``
				: `transform: translate(${this.parallaxX * -48}px, ${this
						.parallaxY * -48}px)`;
		},
	},

	directives: {
		play: {
			bind(el, binding, vnode) {
				el.addEventListener('playing', () => {
					vnode.context[binding.expression] = !el.paused;
				});
				el.addEventListener('pause', () => {
					vnode.context[binding.expression] = !el.paused;
				});
				vnode.context[binding.expression] = !el.paused;
			},
			update(el, binding) {
				if (el.paused) {
					if (binding.value) {
						el.play();
					}
				} else if (!binding.value) {
					el.pause();
				}
			},
		},
	},

	mounted() {
		window.addEventListener('resize', this.check_window_width());
		this.check_window_width();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.check_window_width());
	},

	watch: {
		playing(newVal) {
			if (newVal) {
				document.body.classList.add('hype-video-playing');
			} else {
				document.body.classList.remove('hype-video-playing');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-slide--hype {
	overflow: hidden;
	padding-bottom: 56.25%;

	.c-slide {
		&__play-button {
			@include base-reset;
			max-width: 100%;
			max-height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;

			&:hover,
			&:focus {
				cursor: pointer;

				.fill::before {
					animation: 1s 0.25s filling ease forwards;
				}

				.line::before {
					transform: scaleY(1) translateX(-50%);
				}

				.circle-1 {
					stroke: $color-secondary;
					transition: 0.25s 0.5s;
				}

				.triangle {
					fill: $color-white;
					transition: 0.25s 0.5s;
				}
			}

			.fill {
				border-radius: 50%;
				height: calc(100% - 4px);
				overflow: hidden;
				position: absolute;
				bottom: 4px;
				left: 0;
				width: 100%;
				z-index: -1;

				&::before {
					content: '';
					content: '';
					background: $color-secondary;
					height: 0;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					z-index: -1;
				}
			}

			.line {
				background: $color-white;
				height: calc((((100vw * 0.5625) - 100%) / 2) + 5px);
				pointer-events: none;
				position: absolute;
				top: calc(100% - 4px);
				left: 50%;
				transform: translateX(-50%);
				width: 2px;

				&::before {
					content: '';
					background: $color-secondary;
					height: calc((((100vw * 0.5625) - 100%) / 2) + 5px);
					pointer-events: none;
					position: absolute;
					top: 0;
					left: 50%;
					transform: scaleY(0) translateX(-50%);
					transform-origin: bottom;
					transition: 0.25s;
					width: 2px;
					z-index: 2;
				}
			}

			svg {
				display: inline-block;
				height: 150px;
				position: relative;
				width: 150px;

				circle,
				path {
					transform: scale(0.5);
				}

				.circle-1,
				.triangle {
					transition: 0.25s;
				}
			}
		}

		&__video-container {
			top: 0;
			left: 0;
			transition: 0.25s;

			&::before {
				content: '';
				background: rgba($color: $color-black, $alpha: 0.5);
				height: 100%;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				transition: 0.25s;
				width: 100%;
				z-index: 1;
			}

			&:not(.is-playing) {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	@media screen and (min-width: $breakpoint-x-large) {
		.c-slide {
			&__play-button {
				svg {
					display: inline-block;
					height: 300px;
					width: 300px;

					circle,
					path {
						transform: scale(1);
					}
				}
			}
		}
	}

	@media screen and (min-width: 1281px) {
		padding-bottom: 0;

		.c-slide {
			&__video-container:not(.is-playing) {
				.c-slide__video {
					height: calc(100% + 48px);
					width: calc(100% + 48px);
				}
			}

			&__play-button {
				pointer-events: none;

				svg {
					opacity: 0;
					transition: 0.25s;
				}

				.line {
					transition: 0.25s;
					transform-origin: bottom;
					transform: scaleY(0);
				}

				.s-page.active & {
					pointer-events: auto;

					svg {
						opacity: 1;
						transition: 0.25s 0.75s;
					}

					.line {
						transition: 0.25s 0.5s;
						transform: scaleY(1);
					}
				}
			}
		}
	}
}
</style>
