<template>
	<div class="component c-slide c-slide--experience">
		<div class="c-slide__label text-primary">Experience</div>
		<div class="c-slide__container row">
			<ul class="c-slide__list reset-list d-flex w-100">
				<li
					class="c-slide__item"
					v-for="(page, index) in pages"
					:key="`page-${page.id}`"
				>
					<router-link
						class="d-block text-decoration-none w-100"
						:to="page.slug"
					>
						<div class="c-slide__media-container relative">
							<div
								class="c-slide__media relative overflow-hidden"
							>
								<img
									class="c-slide__image object-fit-positioning object-fit-cover object-position-center"
									:src="`${public_path}images/${page.image}`"
									:alt="page.title"
								/>
							</div>
							<div
								class="c-slide__overlay absolute pa-2 w-100 text-white text-transform-uppercase"
							>
								<div class="c-slide__number hide-on-x-large">
									<span>{{ '0' + (index + 1) }}</span>
									<span class="text-secondary">/</span>
									<span>{{ '0' + pages.length }}</span>
								</div>
								<div class="c-slide__title mt-1">
									{{ page.title }}
								</div>
							</div>
						</div>
						<div class="c-slide__details pt-2">
							<div class="d-flex align-items-center">
								<div class="mr-2">More Details</div>
								<span
									class="sidearm-icon sf-arrows-long-right button --icon"
								></span>
							</div>
						</div>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SlideExperience',

	data() {
		return {
			pages: [
				{
					id: 1,
					image: 'activities.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/activities',
					title: 'Activities',
				},
				{
					id: 2,
					image: 'parking.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/parking-and-directions',
					title: 'Parking & Directions',
				},
				{
					id: 3,
					image: 'policies.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/policies-and-safety',
					title: 'Policies & Safety',
				},
				{
					id: 4,
					image: 'students.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/student-gameday',
					title: 'Student Gameday',
				},
				{
					id: 5,
					image: 'tickets_2024.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/tickets',
					title: 'Tickets',
				},
				{
					id: 6,
					image: 'tailgating.jpg',
					tags: ['Test', 'Test', 'Test'],
					slug: '/tailgating',
					title: 'Tailgating',
				},
			],
			public_path: process.env.BASE_URL,
		};
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-slide--experience {
	.c-slide {
		&__list {
			overflow-x: auto;
			padding-bottom: $spacer;
		}
		&__item {
			flex: 0 0 75%;
			margin-right: $spacer;

			&:last-child {
				margin-right: 0;
			}

			&:hover,
			&:focus {
				.c-slide__image {
					transform: scale(1.1);
				}

				.sidearm-icon {
					color: $color-primary;
					transform: translateX(#{$spacer * 0.25});

					&::after {
						transform: scaleY(1);
					}
				}
			}
		}

		&__media {
			padding-bottom: 100%;

			&::before {
				content: '';
				@include simple-gradient;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 2;
			}
		}

		&__image {
			transition: 0.25s;
		}

		&__overlay {
			font-family: $font-oswald;
			bottom: 0;
			left: 0;
			z-index: 3;
		}

		&__number {
			font-size: 1rem;

			span:nth-child(2) {
				margin: 0 $spacer * 0.5;
			}
		}

		&__title {
			font-size: 1.25rem;
			font-weight: 500;
		}

		&__tags {
			color: $color-light;
			line-height: 1.5;

			&-item::before {
				content: '';
				background: $color-light;
				border-radius: 50%;
				margin-right: $spacer * 0.5;
				height: 6px;
				width: 6px;
			}
		}

		@media screen and (min-width: $breakpoint-large) {
			&__item {
				flex: 0 0 40%;
			}
		}

		@media screen and (min-width: 1281px) {
			&__list {
				flex-wrap: wrap;
				justify-content: center;
				overflow-x: auto;
			}
			&__item {
				display: flex;
				flex: 0 1 33.3333%;
				justify-content: center;
				margin: 0;
				padding: $spacer * 6 $spacer * 0.5;
			}

			&__number {
				font-size: 1.25rem;
			}

			&__title {
				font-size: 1.5rem;
			}
		}
	}
}
</style>
