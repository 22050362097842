<template>
	<div
		class="component c-slide c-slide--hero d-flex align-items-center justify-content-center h-100 w-100"
		v-on:mousemove="update_parallax"
		:style="!mobile ? parallax_bg : ''"
	>
		<div class="c-slide__label text-white relative">Gameday</div>
		<div class="c-slide__details text-center text-white relative">
			<div class="c-slide__subtitle text-transform-uppercase">
				Welcome To
			</div>
			<div class="c-slide__title text-transform-uppercase">Gameday</div>
			<div class="c-slide__sponsor mt-2">
				<a
					href="https://www.heb.com/"
					class="c-slide__sponsor-link d-flex align-items-center justify-content-center text-white text-decoration-none"
					target="_blank"
				>
					<img src="@/assets/sponsor_heb_2024.png" alt="HEB" />
				</a>
			</div>
		</div>
		<div class="c-slide__anchor-menu absolute">
			<div class="c-slide__anchor-menu-icon">
				<span class="sidearm-icon sf-arrow-down-minimal"></span>
			</div>
			<a
				href="#timeline"
				class="c-slide__anchor-menu-button c-slide__anchor-menu-button--timeline absolute"
			>
				<div
					class="c-slide__anchor-menu-tooltip d-flex align-items-center absolute hide-on-medium-down"
				>
					<div class="c-slide__anchor-menu-tooltip__label">
						Timeline
					</div>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-timeline"
					></span>
				</div>
			</a>
			<a
				href="#experience"
				class="c-slide__anchor-menu-button c-slide__anchor-menu-button--experience absolute"
			>
				<div
					class="c-slide__anchor-menu-tooltip d-flex align-items-center absolute hide-on-medium-down"
				>
					<div class="c-slide__anchor-menu-tooltip__label">
						Experience
					</div>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-group"
					></span>
				</div>
			</a>
			<a
				href="#hype"
				class="c-slide__anchor-menu-button c-slide__anchor-menu-button--hype absolute"
			>
				<div
					class="c-slide__anchor-menu-tooltip d-flex align-items-center absolute hide-on-medium-down"
				>
					<div class="c-slide__anchor-menu-tooltip__label">
						Hype
					</div>
					<span aria-hidden="true" class="sidearm-icon sf-tv"></span>
				</div>
			</a>
			<a
				href="#links"
				class="c-slide__anchor-menu-button c-slide__anchor-menu-button--sponsors absolute"
			>
				<div
					class="c-slide__anchor-menu-tooltip d-flex align-items-center absolute hide-on-medium-down"
				>
					<span
						aria-hidden="true"
						class="sidearm-icon sf-info-circle"
					></span>
					<div class="c-slide__anchor-menu-tooltip__label">
						Links
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import { debounce, throttle } from 'lodash-es';

export default {
	name: 'SlideHero',

	data() {
		return {
			mobile: false,
			parallaxX: 0,
			parallaxY: 0,
		};
	},

	methods: {
		check_window_width: debounce(function() {
			if (window.matchMedia('(max-width: 1280px)').matches) {
				this.mobile = true;
			} else {
				this.mobile = false;
			}
		}, 20),
		update_parallax: throttle(function(event) {
			let x = event.clientX;
			let y = event.clientY;
			let screenWidth = window.innerWidth;
			let screenHeight = window.innerHeight;

			this.parallaxX = x / screenWidth;
			this.parallaxY = y / screenHeight;
		}, 20),
	},

	computed: {
		parallax_bg: function() {
			return this.$mq == 'sm'
				? ``
				: `background-position:${this.parallaxX * -48}px ${this
						.parallaxY * -48}px`;
		},
	},

	mounted() {
		window.addEventListener('resize', this.check_window_width());
		this.check_window_width();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.check_window_width());
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-slide--hero {
	background: url('~@/assets/hero_image.jpg') $color-black center no-repeat;
	background-size: cover;
	position: relative;

	&::before {
		content: '';
		background: rgba($color: $color-black, $alpha: 0.25);
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 0;
	}

	.c-slide {
		&__details {
			font-family: $font-oswald;
			line-height: 1.15;
			padding-bottom: $spacer * 12;
			z-index: 1;
		}

		&__subtitle {
			@include responsive_text(375px, 1200px, 28px, 60px);
			font-weight: 200;
			letter-spacing: 0.5rem;
		}

		&__title {
			@include responsive_text(375px, 1200px, 72px, 180px);
			font-weight: 500;
			text-transform: uppercase;
		}

		&__anchor-menu {
			animation: fade-in-up-center 0.5s 0.3s ease forwards;
			opacity: 0;
			left: 50%;
			bottom: $spacer * 6.6667;
			transform: translateX(-50%);

			&-icon {
				border: 2px solid $color-white;
				border-radius: 50%;
				color: $color-white;
				font-size: 30px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				width: 50px;

				.sidearm-icon {
					animation: bounce 1.5s infinite;
					display: inline-block;
				}
			}

			&-button {
				@include pulsating_button;
				background: $color-white;
				border-radius: 50%;
				display: block;
				height: 20px;
				text-decoration: none;
				width: 20px;

				&:hover,
				&:focus {
					.c-slide__anchor-menu-tooltip {
						opacity: 1;
						pointer-events: auto;
						transform: translateY(-50%) scaleX(1);

						&__label,
						.sidearm-icon {
							opacity: 1;
							transition: 0.25s 0.25s;
						}
					}
				}

				&--timeline {
					left: 50%;
					top: $spacer * -4;
					transform: translateX(-50%);
				}

				&--experience {
					top: 50%;
					right: $spacer * -4;
					transform: translateY(-50%);
				}

				&--hype {
					left: 50%;
					bottom: $spacer * -4;
					transform: translateX(-50%);
				}

				&--sponsors {
					top: 50%;
					left: $spacer * -4;
					transform: translateY(-50%);

					.c-slide__anchor-menu-tooltip {
						padding: 0 $spacer * 3.5 0 $spacer;
						left: auto;
						right: calc(100% - #{$spacer * 3});
						transform-origin: right;
					}
				}
			}

			&-tooltip {
				background: rgba($color: $color-white, $alpha: 0.65);
				border-radius: 50px;
				color: $color-primary;
				height: 50px;
				top: 50%;
				left: -$spacer;
				opacity: 0;
				pointer-events: none;
				padding: 0 $spacer 0 $spacer * 3.5;
				text-transform: uppercase;
				transform: translateY(-50%) scaleX(0);
				transform-origin: left;
				transition: 0.25s;
				white-space: nowrap;

				&__label {
					font-family: $font-oswald;
					font-size: 1rem;
					font-weight: 500;
					opacity: 0;
					transition: 0.25s;
				}

				.sidearm-icon {
					opacity: 0;
					transition: 0.25s;
				}
			}
		}
	}

	@media screen and (min-width: 1281px) {
		background-size: calc(100% + 48px);
		transition: 0.1s;
	}
}
</style>
