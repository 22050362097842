<template>
	<div class="component c-slide c-slide--timeline">
		<div class="c-slide__label text-primary">Timeline</div>
		<div
			class="c-slide__container row h-100 w-100 align-items-center justify-content-center"
		>
			<div class="w-100">
				<div class="c-slide__game text-center" v-if="game">
					<div class="c-slide__game-date text-transform-uppercase">
						<span>{{ game.date | moment('MMM. D') }}</span>
						<span
							class="mx-1"
							v-if="game.date && game.opponent.title"
							>|</span
						>
						<span>{{ game.opponent.title }}</span>
					</div>
					<div
						class="c-slide__game-logo my-1"
						v-if="game.opponent.image"
					>
						<img
							:src="
								`${game.opponent.image}?width=76&height=76&mode=crop&scale=both`
							"
							:alt="game.opponent.title"
						/>
					</div>
					<ul
						class="c-slide__game-media reset-list d-flex align-items-center justify-content-center"
					>
						<li
							class="c-slide__game-media-item"
							v-if="game.media.radio"
						>
							<span>Radio:</span>
							<span>{{ game.media.radio }}</span>
						</li>
						<li
							class="c-slide__game-media-item"
							v-if="game.media.tv"
						>
							<span>TV:</span><span>{{ game.media.tv }}</span>
						</li>
						<li class="c-slide__game-media-item">
							<a
								href="https://baylorbears.com/schedule.aspx?path=football"
								target="_blank"
								>Football Schedule</a
							>
						</li>
					</ul>
				</div>
				<ul
					class="c-slide__timeline reset-list d-flex mt-4"
					v-if="timeline"
				>
					<li
						class="c-slide__event d-flex flex-column text-center"
						v-for="(event, index) in timeline"
						:key="index"
					>
						<div
							class="c-slide__event-date mb-2 text-transform-uppercase"
						>
							{{ event.time | moment('h:mm a') }}
						</div>
						<div class="c-slide__event-shape">
							<img
								src="@/assets/x_timeline.svg"
								alt="X Timeline"
								v-if="
									$moment(event.time).isBefore(
										current_date_time,
									)
								"
							/>
						</div>
						<div
							class="c-slide__event-title"
							:class="event.sponsor ? 'mb-2' : ''"
						>
							{{ event.title }}
						</div>
						<a
							class="c-slide__event-sponsor mt-auto d-block text-decoration-none"
							:href="event.sponsor.url"
							target="_blank"
							v-if="event.sponsor"
						>
							<img
								:src="event.sponsor.image"
								:alt="event.sponsor.title"
							/>
						</a>
					</li>
				</ul>
				<DfpAd
					:location="dfp"
					name="gameday-custom---timeline-section-74828c"
					class="c-slide__dfp mt-3"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import DfpAd from '@/components/ads/DfpAd';

export default {
	name: 'SlideTimeline',

	components: {
		DfpAd,
	},

	data() {
		return {
			current_date_time: this.$moment.utc(),
			game: null,
			timeline: null,
			dfp: {
				id: 160,
				title: 'Gameday Custom - Timeline Section',
				type: 'dfp',
				effect: 'none',
				fixed_image_sizes: false,
				excluded_sport_ids: [],
				slick: false,
				dfp: true,
				sidearm_dfp: false,
				common_page: false,
				html_template: null,
				name: 'gameday-custom---timeline-section-74828c',
				autoplay_speed: 8,
				accessibility_icons: false,
				sizes: null,
				slick_options: null,
				dfp_sizes: [
					{
						id: 114,
						enabled: true,
						breakpoint: 1024,
						width: 970,
						height: 0,
						size_list: '[[970,90],[970,66]]',
						unit_name: '/21708449227/BAY',
						sidearm_dfp: null,
					},
					{
						id: 113,
						enabled: true,
						breakpoint: 767,
						width: 728,
						height: 90,
						size_list: null,
						unit_name: '/21708449227/BAY',
						sidearm_dfp: null,
					},
					{
						id: 112,
						enabled: true,
						breakpoint: 0,
						width: 300,
						height: 250,
						size_list: null,
						unit_name: '/21708449227/BAY',
						sidearm_dfp: null,
					},
				],
			},
		};
	},

	methods: {
		get_game() {
			axios
				.get(
					`/services/adaptive_components.ashx?type=events&count=1&start=0&sport_id=0&game_id=${this.$root.game}`,
				)
				.then(response => {
					if (!response || !response.data) return;
					this.game = response.data[0];
				});
		},
	},

	created() {
		if (this.$root.game) {
			this.get_game();
			this.timeline = this.$root.timeline;
		} else {
			this.$root.get_data().then(() => {
				this.get_game();
				this.timeline = this.$root.timeline;
			});
		}
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.c-slide--timeline {
	.c-slide {
		&__game {
			&-date {
				color: $color-primary;
				font-family: $font-oswald;
				font-size: 1.5rem;
			}
			&-media {
				flex-wrap: wrap;

				&-item {
					align-items: center;
					display: flex;

					&:not(:last-child)::after {
						content: '|';
						color: $color-black;
						margin: 0 $spacer * 0.5;
					}

					a {
						color: $color-primary;
						font-weight: 700;
						text-decoration: none;

						&:hover,
						&:focus {
							text-decoration: underline;
						}
					}

					span {
						color: $color-light;
						font-style: italic;
						margin-right: $spacer * 0.25;
					}
				}
			}
		}
		&__timeline {
			justify-content: flex-start;
			max-width: 100%;
			overflow-x: auto;
			padding-bottom: $spacer;
		}
		&__event {
			flex: 0 0 33.3333%;
			margin-right: $spacer;
			position: relative;

			&::after {
				content: '';
				background: #f3f3f3;
				height: 2px;
				pointer-events: none;
				position: absolute;
				top: 80px;
				left: calc(50% + 30px);
				width: 100%;
				z-index: 0;
			}

			&:last-child {
				margin-right: 0;

				&::after {
					content: none;
				}
			}

			&-date {
				color: $color-light;
				font-family: $font-oswald;
				font-size: 1.25rem;
				white-space: nowrap;
			}
			&-shape {
				background: $color-white;
				border: 2px solid #ededed;
				border-radius: 50%;
				height: 60px;
				margin: 0 auto;
				position: relative;
				width: 60px;
				z-index: 1;

				&::after {
					content: '';
					background: #ededed;
					display: block;
					height: 80px;
					pointer-events: none;
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					width: 2px;
				}

				img {
					height: 70px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 53px;
				}
			}

			&-title {
				margin-top: 92px;
			}
		}
	}
	@media screen and (min-width: $breakpoint-medium) {
		.c-slide {
			//for naming
			&__event {
				flex: 0 0 25%;
			}
		}
	}
	@media screen and (min-width: $breakpoint-large) {
		.c-slide {
			//for naming
			&__event {
				flex: 0 0 20%;
			}
		}
	}
	@media screen and (min-width: $breakpoint-x-large) {
		.c-slide {
			//for naming
			&__event {
				flex: 0 0 15%;
			}
		}
	}
	@media screen and (min-width: 1281px) {
		.c-slide {
			//for naming
			&__game {
				margin-top: $spacer * 4;
			}
			&__timeline {
				justify-content: center;
			}
			&__event {
				flex: 1 1 10%;

				.s-page.active & {
					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							.c-slide__event-shape img {
								opacity: 1;
								transition: opacity 0.25s 0.45s * $i !important;
							}
						}
					}
				}

				&-shape {
					img {
						opacity: 0;
						transition: 0.25s 0.5s;
					}
				}
			}
			&__dfp {
				margin-bottom: $spacer * 3;
			}
		}
	}
}
</style>
